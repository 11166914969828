import React, { Component } from "react";
import NavBar from "../components/NavBar";
import FeatureIcon from "../components/FeatureIcon";
import Funfact from "../components/Funfact";
import TeamMemberGrid from "../components/TeamMemberGrid";
import TestimonialSlider from "../components/TestimonialSlider";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import ModalVideo from "react-modal-video";
class About extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/garment.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>About Us</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>About Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className="page-wrapper section-space--inner--top--120">
          {/*About section start*/}
          <div className="about-section section-space--inner--bottom--120">
            <div className="container">
              <div className="row row-25 align-items-center">
                <div className="col-lg-6 col-12 mb-30">
                  <div className="about-image-two">
                    <span className="video-popup">
                      <img src="assets/img/about/123.jpg" alt="" />
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-12 mb-30">
                  <div className="about-content-two">
                    <h3>Selamat Datang di 99 Apparel</h3>
                    <h1>Kami fokus pada kualitas</h1>
                    <h4>Berkualitas, Terkontrol, dan On Time</h4>
                    <p>
                      99 Apparel berdiri sejak 2018 dan merupakan usaha konveksi
                      yang selain didukung oleh tenaga handal sesuai bidangnya
                      masing-masing tetapi kami juga dilengkapi dengan fasilitas
                      peralatan produksi mulai dari mesin jahit dengan berbagai
                      fungsi yang berbeda, mesin bordir computer kepala 6, dan
                      mesin printing sublime lengkap dengan mesin pressnya.
                      Sehingga memudahkan kami dalam memfasilitasi pekerjaan
                      kami sehingga didapat hasil yang berkualitas, terkontrol,
                      dan deliveri ontime sesuai jadwal yang ditentukan.
                    </p>
                    <a
                      href="contact-us"
                      className="ht-btn--default ht-btn--default--dark-hover section-space--top--20"
                    >
                      Hubungi Kami
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

          {/* Feature Icon */}
          {/* <FeatureIcon background="grey-bg" /> */}

          {/* Fun fact */}
          <Funfact />

          {/* Team member */}
          {/* <TeamMemberGrid /> */}

          {/* Testimonial Slider */}
          {/* <TestimonialSlider /> */}

          {/* Brand logo */}
          <BrandLogoSlider background="" />
        </div>

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default About;
