import React, { Component } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import { Modal } from "bootstrap";
class Contact extends Component {
  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div
          className="breadcrumb-area breadcrumb-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/garment.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Contact Us</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>Contact Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}
        {/*====================  content page content ====================*/}
        <div className="page-wrapper section-space--inner--120">
          {/*Contact section start*/}
          <div className="conact-section">
            <div className="container">
              <div className="row section-space--bottom--50">
                <div className="col">
                  <div className="contact-map">
                    <iframe
                      title="google-map"
                      src="https://maps.google.com/maps?width=675&amp;height=448&amp;hl=en&amp;q=Graha Sedayu Regency, Blok D 39 Kelurahan Sembung Harjo Genuk Semarang&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-12">
                  <div className="contact-information">
                    <h3>Kontak Informasi</h3>
                    <ul>
                      <li>
                        <span className="icon">
                          <i className="ion-android-map" />
                        </span>
                        <span className="text">
                          <span>
                            Graha Sedayu Regency, Blok D 39 Kelurahan Sembung
                            Harjo Genuk Semarang
                          </span>
                        </span>
                      </li>
                      <li>
                        <span className="icon">
                          <i className="ion-ios-telephone-outline" />
                        </span>
                        <span className="text">
                          <a href="https://alvo.chat/41G">0822 2646 5253</a>
                          <a href="https://alvo.chat/41G">0822 2646 5253</a>
                        </span>
                      </li>
                      <li>
                        <span className="icon">
                          <i className="ion-ios-email-outline" />
                        </span>
                        <span className="text">
                          <a href="mailto:99apparelsmg@gmail.com">
                            99apparelsmg@gmail.com
                          </a>
                          <a href="mailto:99apparelsmg@gmail.com">
                            99apparelsmg@gmail.com
                          </a>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8 col-12">
                  <div className="contact-form">
                    <h3>Persetujuan Kerjasama</h3>
                    <div id="contact-form">
                      <div className="col-12">
                        <p>
                          Pemesanan akan dituangkan dalam PO dan MOU pembayaran
                          yaitu:
                        </p>

                        <p>
                          <strong>1. Kesepakatan harga,</strong> setelah kita
                          sama-sama mengetahui bahan yang akan digunakan dan
                          mengetahui quantity fix order
                        </p>
                        <p>
                          <strong>2. Proses desain model</strong> akan dilakukan
                          setelah menerima DP pertama, selambat-lambatnya 2 hari
                          setelah ada kesepakatan harga dan pembayaran DP
                          pertama masuk
                        </p>
                        <p>
                          <strong>3. Approval desain model</strong> akan
                          disampaikan ke customer dalam bentuk form dan wajib
                          ditandatangani sebagai bentuk persetujuan fix desain
                          order dan sudah tidak boleh direvisi lagi
                        </p>
                        <p>
                          <strong>
                            4. Revisi desain order hanya boleh dilakukan
                            maksimal 2x
                          </strong>{" "}
                          untuk menghindari kesalahan desain dan dituangkan
                          dalam form yang sudah disediakn dari kami
                        </p>
                        <p>
                          <strong>5. Pembuatan sample akan</strong> dibuat max 7
                          hari dari penandatanganan approval desain dan{" "}
                          <strong>
                            hanya akan dilakukan untuk order di atas 150pcs
                          </strong>
                          , untuk quantity dibawah 150 bisa dengan melihat
                          sample yang kami sediakan di kantor kami
                        </p>
                        <br />
                        <p>
                          Setelah proses diatas selesai, untuk selanjutnya akan
                          dituangkan dalam PO dan MOU pembayaran yaitu:
                        </p>
                        <p>
                          1. Pembayaran DP pertama adalah 50% pada saat
                          penandatangan PO dan MOU
                        </p>
                        <p>
                          2. Pelunasan dibayarkan saat order selesai dan barang
                          siap diambil/dikirim
                        </p>
                        <p>
                          3. Proses produksi ditentukan oleh jumlah pesanan dan
                          tingkat kerumitan dan akan didiskusikan dengan
                          customer
                        </p>
                        <p>
                          4. Harga sesuai daftar harga terlampir dan masih dapat
                          disesuaikan dengan jumlah pesanan fix order{" "}
                          <strong>(NEGOTIABLE)</strong>
                        </p>
                        <a href="https://alvo.chat/41G">
                          <button>Hubungi Kami</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Contact section end*/}
        </div>
        {/*====================  End of content page content  ====================*/}

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Contact;
