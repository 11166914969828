import React, { Component } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

class Funfact extends Component {
  // state = {
  //   didViewCountUp: false,
  // };

  // onVisibilityChange = (isVisible) => {
  //   if (isVisible) {
  //     this.setState({ didViewCountUp: true });
  //   }
  // };

  render() {
    let data = [
      {
        featureIcon: "why-us1.png",
        featureTitle: "Workshop",
        featureDescription:
          "Workshop milik sendiri dengan mesin standar profesional",
      },
      {
        featureIcon: "why-us4.png",
        featureTitle: "Jasa Desain",
        featureDescription: "Gratis jasa desain produk untuk setiap pemesanan",
      },
      {
        featureIcon: "why-us3.png",
        featureTitle: "Staff Berpengalaman",
        featureDescription: "Dikerjakan oleh staf yang ahli dan berpengalaman",
      },
      {
        featureIcon: "why-us2.png",
        featureTitle: "Sampel Produk",
        featureDescription: "Layanan pembuatan sampel produk setiap pemesanan",
      },
    ];

    let DataList = data.map((val, i) => {
      return (
        <div className="col-lg-3 col-md-6" key={i}>
          <div className="single-feature-icon">
            <div className="single-feature-icon__image">
              <img
                src={`assets/img/icons/${val.featureIcon}`}
                className="img-fluid icon-funfact"
                alt=""
              />
            </div>
            <h3 className="single-feature-icon__title">{val.featureTitle}</h3>
            <p className="single-feature-icon__content">
              {val.featureDescription}
            </p>
          </div>
        </div>
      );
    });

    return (
      <div>
        {/*====================  fun fact area ====================*/}
        <div
          className="funfact-section section-space--inner--100 funfact-bg"
          style={{
            backgroundImage: `url(assets/img/backgrounds/garment.jpg)`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="fun-fact-wrapper">
                  <h1
                    style={{
                      color: "white",
                      textAlign: "center",
                      marginBottom: "8vh",
                    }}
                  >
                    Alasan Memilih Kami
                  </h1>
                  <div className="row">{DataList}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of fun fact area  ====================*/}
      </div>
    );
  }
}

export default Funfact;
